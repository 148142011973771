<template>
  <div v-if="shouldShowDetails" class="subtitle is-7 record-detail-snapshot columns is-multiline">
    <div v-if="rankingCriteria" class="ranking-criteria is-12 column">
      <span class="subtitle is-block has-text-weight-bold has-text-grey">
        <span>
          {{ rankingCriteria.field }}
        </span>
        <span>
          <span class="icon">
            <fa icon="chevron-right" />
          </span>
        </span>
        <span />
        {{ format(rankingCriteria.type, rankingCriteria.value) }}
      </span>
    </div>
    <div class="column">
      <div v-if="location.address">
        <span class="icon">
          <fa icon="map-marker" />
        </span>
        <span v-html="location.address.toUpperCase()" />
      </div>
      <div class="has-location-subtext">
        <span v-if="location.city">
          {{ location.city.toUpperCase() }},
        </span>
        <span v-if="location.state">
          {{ location.state.toUpperCase() }}
        </span>
        <span v-if="location.zip">
          {{ location.zip }}
        </span>
      </div>
    </div>
    <div class="column">
      <p v-if="location.phone || record.phone">
        <span class="icon">
          <fa icon="phone" />
        </span>
        <span>{{ location.phone || record.phone }}</span>
      </p>
      <p v-if="location.website || record.website">
        <span class="icon">
          <fa icon="globe" />
        </span>
        <span>{{ location.website || record.website }}</span>
      </p>
      <p v-if="location.email || record.email">
        <span class="icon">
          <fa icon="envelope" />
        </span>
        <span>{{ location.email || record.email }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    shouldShowDetails() {
      return (this.record?.locations?.length ?? 0) > 0;
    },
    rankingCriteria() {
      const rankKey = this.currentDirectory.primary_filter.field;
      const routeQuery = this.$route.query;

      let currentList = false;

      // Ensure the route has the rankKey
      if (!Object.keys(routeQuery).includes(rankKey)) {
        return false;
      }

      // Ensure there is only one rankKey
      if (routeQuery[rankKey].includes('~')) {
        return false;
      }

      // Ensure the record has a rank that is not 9999
      if ((this.record?.rank ?? 9999) === 9999) {
        return false;
      }

      // Ensure the record has one ranking criteria
      (this.record.lists ?? []).forEach((list) => {
        if (list.key === routeQuery[rankKey]) {
          currentList = list;
        }
      });

      // add the recrod type
      (this.currentDirectory.fields ?? []).forEach((field) => {
        if (currentList.field === field.name) {
          currentList.type = field.type;
        }
      });

      return currentList;
    },
    location() {
      return this.record.locations[0];
    },
  },
  methods: {
    format(type, field) {
      return this.formatField(type, field);
    },
  },
};
</script>
